import { contentsService } from '../services';
import { contentsConstants } from "../constants"

export const contentsActions = {
  getFixedContent,
  getContents,
  getContentsByStatus
};

function getFixedContent() {
  return (dispatch) => {
    dispatch({
      type: contentsConstants.GET_FIXED_CONTENT_REQUEST
    })

    contentsService.getFixedContent()
      .then((response) => {
        dispatch({
          type: contentsConstants.GET_FIXED_CONTENT_SUCCESS,
          payload: response.data
        });
      })
      .catch((response) => {
        dispatch({
          type: contentsConstants.GET_FIXED_CONTENT_FAILURE
        })
      });
  }
}

function getContents(page, sort, limit, searchText) {
  return async (dispatch) => {
    dispatch({ type: contentsConstants.GET_ALL_CONTENT_REQUEST });

    try {
      const contents = await contentsService.getCotents(page, sort, limit, searchText);

      dispatch({
        type: contentsConstants.GET_ALL_CONTENT_SUCCESS,
        payload: contents
      });
    } catch (e) {
      dispatch({
        type: contentsConstants.GET_ALL_CONTENT_FAILURE,
        error: e.toString()
      });
    }
  }
}

function getContentsByStatus(page, sort, limit, status) {
  return async (dispatch) => {
    dispatch({ type: contentsConstants.GET_CONTENTS_BY_STATUS_REQUEST });

    try {
      const contents = await contentsService.getCotentsByStatus(page, sort, limit, status);

      console.log('contents', contents)

      dispatch({
        type: contentsConstants.GET_CONTENTS_BY_STATUS_SUCCESS,
        payload: contents
      });
    } catch (e) {
      dispatch({
        type: contentsConstants.GET_CONTENTS_BY_STATUS_FAILURE,
        error: e.toString()
      });
    }
  }
}