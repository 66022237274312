import React, { useEffect, useState } from 'react';
import { UploadFile } from './UploadFile';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { useDispatch, useSelector } from 'react-redux';
import { ideaUpdateActions } from '../../actions';
import { VideoPreview } from './VideoPreview';
import { FileList } from './FileList';
import { EditImageList } from './EditImageList';
import { Box } from '@material-ui/core';
import { history } from '../../helpers';

function EditUpload({ type, img, name, message, accept, maxSize }) {
  const dispatch = useDispatch();
  const { images, video, files, attachments } = useSelector((state) => state.ideaUpdate);


  useEffect(() => {
    if (type === 'photo') {
      setDefaultImgs();
    };

     if (type === 'file') {
      console.log(type)
      setDefaultFiles();
    };
  }, []);

  function filterDefaultAtt(type) {
    return attachments.filter((item) => item.mimeType.container === type)
      .map(({ url, _id, fileName }) => ({
        id: _id,
        name: fileName,
        url: url,
     }));
    };

  function setDefaultImgs() {
    const imgs = filterDefaultAtt('images');
    if (imgs.length > 0) {
      dispatch(ideaUpdateActions.updateSetImages(imgs));
    };
  };

  function setDefaultFiles() {
    const defaultFiles = filterDefaultAtt('dynamic-files');
    if (defaultFiles.length > 0) {
      dispatch(ideaUpdateActions.updateSetFile(defaultFiles));
    };
  };


  function handleUpload(archives) {
    const newUploadedFiles = archives.map((file) => ({
      file,
      type: file.type,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      url: null,
    }));

    if (type === 'photo') {
      dispatch(ideaUpdateActions.updateSetImages(newUploadedFiles));
    };

    if (type === 'video') {
      dispatch(ideaUpdateActions.updateSetVideo(newUploadedFiles[0]));
    };

    if (type === 'file') {
      dispatch(ideaUpdateActions.updateSetFile(newUploadedFiles));
    };
  };

  function render() {
    if (type === 'photo') {
      return images.length === 0 && <img src={img} alt={name} />;
    };

    if (type === 'video') {
      return Object.keys(video).length === 0 && <img src={img} alt={name} />;
    };

    if (type === 'file') {
      return files.length === 0 && <img src={img} alt={name} />;
    };
  };

  return (
    <Box>
      {images.length === 0 && type === 'photo' && (
        <UploadFile
          onUpload={handleUpload}
          name={name}
          message={message}
          accept={accept}
          maxSize={maxSize}
        >
          {render()}
        </UploadFile>
      )}

      {Object.keys(video).length === 0 && type === 'video' && (
        <UploadFile
          onUpload={handleUpload}
          name={name}
          message={message}
          accept={accept}
          maxSize={maxSize}
        >
          {render()}
        </UploadFile>
      )}

      {files.length === 0 && type === 'file' && (
        <UploadFile
          onUpload={handleUpload}
          name={name}
          message={message}
          accept={accept}
          maxSize={maxSize}
        >
          {render()}
        </UploadFile>
      )}

      {type === 'photo' && !!images.length && <EditImageList files={images} />}
      {type === 'video' && Object.keys(video).length > 0 && <VideoPreview isEdit={true} video={video} />}
      {type === 'file' && !!files.length && <FileList sEdit={true} files={files} />}
    </Box>
  );
}

export { EditUpload };
