export const contentsConstants = {
  GET_FIXED_CONTENT_REQUEST: 'GET_FIXED_CONTENT_REQUEST',
  GET_FIXED_CONTENT_SUCCESS: 'GET_FIXED_CONTENT_SUCCESS',
  GET_FIXED_CONTENT_FAILURE: 'GET_FIXED_CONTENT_FAILURE',
  GET_ALL_CONTENT_REQUEST: 'GET_ALL_CONTENT_REQUEST',
  GET_ALL_CONTENT_SUCCESS: 'GET_ALL_CONTENT_SUCCESS',
  GET_ALL_CONTENT_FAILURE: 'GET_ALL_CONTENT_FAILURE',
  GET_CONTENTS_BY_STATUS_REQUEST: 'GET_CONTENTS_BY_STATUS_REQUEST',
  GET_CONTENTS_BY_STATUS_SUCCESS: 'GET_CONTENTS_BY_STATUS_SUCCESS',
  GET_CONTENTS_BY_STATUS_FAILURE: 'GET_CONTENTS_BY_STATUS_FAILURE',
};
