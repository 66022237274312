import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Button } from './styles';

export function ArticlePrevArrow(props) {
  const { onClick } = props;

  return (
    <Button
      onClick={onClick}
      style={{ position: 'absolute', left: 15, top: '40%', zIndex: 1 }}
    >
      <ChevronLeftIcon fontSize="large" />
    </Button>
  );
}