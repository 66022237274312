import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Divider, Typography } from '@material-ui/core';

import {
  ContentsContainer,
  ContentsCardTitle,
  NoteIcon,
  TextSeeAll,
  ContentsCard,
  Link,
  ContentDescription,
  Image
} from './styles';
import { truncate, history } from '../../../helpers';
import { CustomTooltip } from '../../Shared/CustomTooltip';

export function Contents() {
  const { t } = useTranslation();
  const { contentsByStatus } = useSelector(state => state.contents);

  function handleSeeAllContent() {
    history.push('/contents-details');
  }
  
  return (
    <ContentsContainer>
      <ContentsCardTitle elevation={0}>
        <Box display="flex" alignItems="center">
          <NoteIcon />
          <Typography variant="subtitle2">{t('conteudos')}</Typography>
        </Box>
        <TextSeeAll onClick={handleSeeAllContent}>{t('ver_todos')}</TextSeeAll>
      </ContentsCardTitle>

      <ContentsCard container>
        { contentsByStatus &&
          contentsByStatus.slice(0, 4).map((content, index) => {
            let renderDivider = true;

            if (index >= 3) {
              renderDivider = false;
            }

            return (
              <Grid key={content._id} item xs={12}>
                <CustomTooltip title={content.title}>
                  {content.link ? (
                    <Typography variant="subtitle2">
                      <Link
                        href={content.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ wordWrap: 'break-word' }} 
                      >
                        {content.title}
                      </Link>
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">
                      {truncate(content.title, !content?.thumbnail?.url ? 60 : 28)}
                    </Typography>
                  )}
                </CustomTooltip>
                <ContentDescription>
                  {truncate(content.description, !content?.thumbnail?.url ? 120 : 60)}
                </ContentDescription>
                {content?.thumbnail?.url && (
                  <Image src={content.thumbnail.url} alt="banner content" />
                )}
                {renderDivider && index !== (contentsByStatus.length - 1) && <Divider style={{ margin: '1rem 0' }} />}
              </Grid>
            )
          })}
      </ContentsCard>
    </ContentsContainer>
  );
}