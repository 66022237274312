import { walletConstants } from '../constants';
import update from 'react-addons-update';

const initialState = {
  wallets: [],
  points: null,
  loading: false,
  paging: {
    page: 1,
    quantityPerPage: 10,
    filter: {
      classification: 'all',
      period: 'all',
    },
    sort: { createdAt: -1 },
    query: '',
    total: 0,
  },
};

export function wallet(state = initialState, action) {
  switch (action.type) {
    case walletConstants.GET_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
      }
      
    case walletConstants.GET_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        wallets: action.payload.wallets,
        paging: {
          ...state.paging,
          total: action.payload.paging.total
        }
      }

    case walletConstants.GET_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case walletConstants.GET_WALLET_MOBILE_REQUEST:
      return update(state, {
        loading: { $set: true }
      });  

    case walletConstants.GET_WALLET_MOBILE_SUCCESS:
      return update(state, {
        loading: { $set: false },
        wallets: { $push: action.payload.wallets },
        paging: {
          page: { $set: parseInt(action.payload.paging.page) + 1 },
          total: { $set: action.payload.paging.total },
        }
      });

    case walletConstants.GET_WALLET_MOBILE_FAILURE:
      return state;

    case walletConstants.GET_POINTS_SUCCESS:
      return update(state, {
        points: { $set: action.payload },
      });

    case walletConstants.GET_POINTS_FAILURE:
      return state;

    case walletConstants.SET_QUANTITY_PER_PAGE:
      return update(state, {
        paging: {
          quantityPerPage: { $set: action.payload.quantityPerPage },
        },
      });

    case walletConstants.SET_PAGE:
      return update(state, {
        paging: {
          page: { $set: action.payload.page },
        },
      });

    case walletConstants.SET_STATUS:
      return update(state, {
        paging: {
          filter: {
            classification: { $set: action.payload.status },
          },
        },
      });

    case walletConstants.SET_PERIOD:
      return update(state, {
        paging: {
          filter: {
            period: { $set: action.payload.period },
          },
        },
      });

    case walletConstants.SET_QUERY:
      return update(state, {
        paging: {
          query: { $set: action.payload.query }
        },
      });

    case walletConstants.CLEAR_WALLET: {
      return initialState;
    }
    
    default:
      return state;
  }
}
