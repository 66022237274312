import React, { useState, useEffect, Fragment } from 'react'
import { ReportLayout } from "./../../components"
import {
  Tabs,
  Tab,
  makeStyles,
  TableCell,
  Box,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import TableChartIcon from '@material-ui/icons/TableChart';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import { TablePanel } from "./../../components"
import { GraphicPanel, PieChartImagine } from "./../../components"
import { useSelector, useDispatch } from "react-redux";
import { tableCss } from './../../components/Tables/TableCss';
import moment from 'moment';
import config from '../../config';
import { useWindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { reportActions } from "./../../actions"
import { CustomTooltip } from '../../components/Shared';
import { history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  tabs: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: 8, 
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    background: 'white',
  },
  buttonChart: {
    marginRight: 16
  },
  itemTab: {
    fontsize: 14,
    fontWeight: 600,
    textTransform: "lowercase",
    marginLeft: theme.spacing(0.8)
  },
}))

export const ReportCustoRetorno = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const [selectedChart, setSelectedChart] = useState("graphCustoReal");
  const [chartData, setChartData] = useState([])
  const [tabIndex, setTabIndex] = useState(0);
  const { report, chart, tab } = useSelector(state => state.report)
  const tableClasses = tableCss();
  const { t } = useTranslation();

  function formatBRL(value) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
      .format(parseFloat(value).toFixed(2))
  }

  function handleChangeChart(id) {
    setSelectedChart(id)
  }

  function handleView(id) {
    window.open(`/idea/${id}/general`, '_blank');
  }

  const chartType = [
    { id: "graphCustoReal", name: t('custo_real') },
    { id: "grpahRetornoReal", name: t('retorno_real') },
    { id: "graphRetornoCusto", name: t('retorno_custo') },
  ]

  const tableInfo = {
    columns: [
      { id: 'ideaName', label: t('nome_da_ideia') },
      { id: 'codigo', label: t('codigo') },
      { id: 'data', label: t('data') },
      { id: 'custoEstimado', label: t('custo_estimado') },
      { id: 'retornoEstimado', label: t('retorno_estimado') },
      { id: 'custoReal', label: t('custo_real') },
      { id: 'retornoReal', label: t('retorno_real') },
      { id: 'aprovador', label: t('aprovador') },
      { id: 'local', label: t('local') },
      { id: 'areaOcupacao', label: t('area_de_ocupacao') },
    ],
    data: report.map(item => (
      <Fragment>
        <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>
          <CustomTooltip title={item.name} aria-label={item.name} placement="bottom-start">
            <Typography variant="subtitle2" className={tableClasses.link} onClick={() => handleView(item._id)}>{item.name}</Typography>
          </CustomTooltip>
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.code}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{moment(item.createAt).format('DD/MM/YYYY')}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{formatBRL(item.custoEstimado)}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{formatBRL(item.retornoEstimado)}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{formatBRL(item.custoReal)}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{formatBRL(item.retornoReal)}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.approver}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{item.local}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>{item.area}</TableCell>
      </Fragment>
    )),
  }

  useEffect(() => {
    if (chart) {
      switch (selectedChart) {
        case "graphCustoReal":
          setChartData(chart.map(item => ({
            name: item.name,
            count: item.custoReal
          })))
          break;

        case "graphRetornoReal":
          setChartData(chart.map(item => ({
            name: item.name,
            count: item.retornoReal
          })))
          break;

        case "graphRetornoCusto":
          setChartData(chart.map(item => ({
            name: item.name,
            count: item.custoRetornoReal
          })))
          break;

        default:
          break;
      }
    }
  }, [selectedChart, chart])

  useEffect(() => {
    return () => {
      dispatch(reportActions.clearAll())
    }
  }, [dispatch])

  function handleChangeTab(index) {
    dispatch(reportActions.changeTab(index === 0 ? "table" : "graph"));
    setTabIndex(index)
  }

  return (
    <ReportLayout title={t('custos_e_retorno')} tableInfo={tableInfo.columns}>
      <Tabs className={classes.tabs} value={tabIndex} onChange={(e, value) => handleChangeTab(value)} aria-label="Tabs de grafico e tabela" indicatorColor="primary" textColor="primary" variant="fullWidth">
        <Tab
          aria-controls="Panel tabela"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <TableChartIcon />
              <Typography className={classes.itemTab}>
                {t('tabela')}
              </Typography>
            </Box>
          )}
        />
        <Tab
          aria-controls="Panel grafico"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <DonutLargeIcon />
              <Typography className={classes.itemTab}>
                {t('grafico')}
              </Typography>
            </Box>
          )}
        />
      </Tabs>

      {tab === "table" && (
        <TablePanel tableInfo={tableInfo} />
      )}

      {tab === "graph" && (
        <GraphicPanel>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box display="flex">
                {chartType.map((item, key) => (
                  <Button
                    color="primary"
                    key={key}
                    className={classes.buttonChart}
                    onClick={() => handleChangeChart(item.id)}
                    variant={selectedChart === item.id ? "contained" : "outlined"}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <PieChartImagine data={chartData} type={selectedChart} />
            </Grid>
          </Grid>
        </GraphicPanel>
      )}
    </ReportLayout>
  )
}
