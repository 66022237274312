const config = {
  URL_BASE: process.env.REACT_APP_URL_BASE,
  URL_TOKEN: process.env.REACT_APP_URL_TOKEN,
  URL_SOCKET: process.env.REACT_APP_URL_SOCKET,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  RECAPTCHA_ID: process.env.REACT_APP_RECAPTCHA_ID,
  GOOGLE_ANALYTCS: JSON.parse(process.env.REACT_APP_GOOGLE_ANALYTCS),
  GOOGLE_ANALYTCS_ID: process.env.REACT_APP_GOOGLE_ANALYTCS_ID,
  HOTJAR: JSON.parse(process.env.REACT_APP_HOTJAR),
  HOTJAR_ID: process.env.REACT_APP_HOTJAR_ID,
  OFFICE365_ID: process.env.REACT_APP_OFFICE365_ID,
  OFFICE365_AUTH_URL: process.env.REACT_APP_OFFICE365_AUTH_URL,
  GOOGLE_ID: process.env.REACT_APP_GOOGLE_ID,
  GOOGLE_AUTH_URL: process.env.REACT_APP_GOOGLE_AUTH_URL,
  LOGIN_FORM: JSON.parse(process.env.REACT_APP_LOGIN_FORM),
  LOOPING_NOTIFICATION: JSON.parse(process.env.REACT_APP_LOOPING_NOTIFICATION),
  RESPONSIVE_MOBILE: 600,
  SHOW_GOOGLE_FORM: JSON.parse(process.env.REACT_APP_SHOW_GOOGLE_FORM),
  SHOW_OFFICE365_FORM: JSON.parse(process.env.REACT_APP_SHOW_OFFICE365_FORM),
  LANGUAGE_SELECT: JSON.parse(process.env.REACT_APP_LANGUAGE_SELECT),
  PERSONALIZED_CLASSIFICATION_COLABORATOR: JSON.parse(process.env.REACT_APP_PERSONALIZED_CLASSIFICATION_COLABORATOR),
  PERSONALIZED_CLASSIFICATION_MANAGER: JSON.parse(process.env.REACT_APP_PERSONALIZED_CLASSIFICATION_MANAGER),
  SHOW_RANKING_HOME_CARD: JSON.parse(process.env.REACT_APP_SHOW_RANKING_HOME_CARD),
  SHOW_MENU_OPTION_RANKING: JSON.parse(process.env.REACT_APP_SHOW_MENU_OPTION_RANKING),
  SHOW_MENU_OPTION_PORTFOLIO: JSON.parse(process.env.REACT_APP_SHOW_MENU_OPTION_PORTFOLIO),
  SHOW_PORTFOLIO_IDEA_EXECUTION: JSON.parse(process.env.REACT_APP_PORTFOLIO_IDEA_EXECUTION),
  SHOW_PERSONALIZED_EVALUATION_ON_IDEA: JSON.parse(process.env.REACT_APP_SHOW_PERSONALIZED_EVALUATION_ON_IDEA),
  BUILD_VERSION: JSON.parse(process.env.REACT_APP_BUILD_VERSION),
  SHOW_PERSONALIZED_EVALUATION_CRUD_FORM: JSON.parse(process.env.REACT_APP_SHOW_PERSONALIZED_EVALUATION_CRUD_FORM),
  DEFAULT_ROUTE_AFTER_LOGIN: process.env.REACT_APP_DEFAULT_ROUTE_AFTER_LOGIN,
  SHOW_REWARD_FIELDS_ON_CHALLENGE: JSON.parse(process.env.REACT_APP_SHOW_REWARD_FIELDS_ON_CHALLENGE),
  ALLOW_IDEIAS_WITHOUT_CHALLENGE: JSON.parse(process.env.REACT_APP_ALLOW_IDEIAS_WITHOUT_CHALLENGE),
  SHOW_IDEAS_BY_STEP_FLUX: JSON.parse(process.env.REACT_APP_SHOW_IDEAS_BY_STEP_FLUX),
  NUMBER_AREAS_IDEA_CREATE: JSON.parse(process.env.REACT_APP_NUMBER_AREAS_IDEA_CREATE),
  SHOW_TIMELINE_CHALLENGES: JSON.parse(process.env.REACT_APP_SHOW_TIMELINE_CHALLENGES),
  SHOW_TIMELINE_CONTENTS: JSON.parse(process.env.REACT_APP_SHOW_TIMELINE_CONTENTS),
  TIMELINE_ADD_INOVATION: JSON.parse(process.env.REACT_APP_TIMELINE_ADD_INOVATION)
};

export default config;
