import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { executionActions } from '../../../actions';
import { useIdeaDetailsController } from '../../../containers/IdeaDetailsPage';
import { fluxOfIdeasActions } from '../../../actions';
import config from '../../../config';

export function useIdeaExecutionController() {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [openDialogJustification, setOpenDialogJustification] = useState(false);
  const {
    loading,
    estimatedStartDate,
    estimatedConclusionDate,
    executionDetails,
    estimatedFinancialReturn,
    costsFields,
  } = useSelector((state) => state.execution);
  const { typeProfile } = useSelector((state) => state.user);
  const { hasIdeaFlux } = useIdeaDetailsController();
  const visualizationConfig = hasIdeaFlux?.currentStepFlux?.ideaStepFlux?.visualizationConfigs;

  function openModelJustification() {
    setOpenDialogJustification(true);
  }

  function onCloseDialogJustification() {
    setOpenDialogJustification(false);
  }

  async function sendExecution() {
    const ideaStepFlux = hasIdeaFlux?.currentStepFlux?.ideaStepFlux;
    const regexPattern = new RegExp(',', 'g');
    const regexPointPattern = new RegExp('[.]', 'g');

    let executionData = {
      estimatedStartDate,
      estimatedConclusionDate,
      executionDetails,
      estimatedFinancialReturn: estimatedFinancialReturn.replace(regexPointPattern, '').replace(regexPattern, '.'),
      costs: costsFields.map(item => {
        let estimatedCost = item.estimatedCost;
        estimatedCost = estimatedCost.replace(regexPointPattern, '').replace(regexPattern, '.');

        return {
          ...item,
          estimatedCost: estimatedCost
        }
      })
    }

    await dispatch(executionActions.executeIdea(executionData, id, t('ideia_em_execucao'), typeProfile.type));

    if (config.SHOW_IDEAS_BY_STEP_FLUX) {
      let evaluationData = {
        decision: ideaStepFlux?.decisions.filter(item => item.typeDecision === 'NextStepApproved')[0],
        managerObservation: executionDetails
      }
  
      await dispatch(fluxOfIdeasActions.updateIdeaStageInFlux(
        id,
        evaluationData,
        t('sua_avaliacao_foi_feita_com_sucesso'),
        ideaStepFlux?.attachmentsRequired
      ));
    }

    if (typeProfile.type !== 'colaborator') {
      history.push('/curation');
    } else {
      history.push('/my-ideas');
    }

    setDialogIsOpen(false);
  }

  function openDialog() {
    setDialogIsOpen(true);
  }

  function closeDialog() {
    setDialogIsOpen(false);
  }
  
  return {
    id,
    loading,
    dialogIsOpen,
    estimatedStartDate,
    estimatedConclusionDate,
    executionDetails,
    openModelJustification,
    openDialogJustification,
    onCloseDialogJustification,
    sendExecution,
    openDialog,
    closeDialog,
    visualizationConfig,
    hasIdeaFlux
  }
}