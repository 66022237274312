import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import { history, truncate } from '../../helpers';
import { CardMedia } from '@material-ui/core';
import imageCard from '../../assets/imgs/imagem_padrao_card_desafio.png';


const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    '& .MuiTypography-root': {
      textTransform: 'none',
    },
    height: 240
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    color: '#2F2F2F',
  },
  description: {
    color: '#2F2F2F',
    fontSize: 13,
  },
  media: {
    width: '100%',
    height: 158,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

function ArticleCard({ title, description, link, image, ...props }) {
  const classes = useStyles();

  function goToArticle() {
    history.push(link);
  }

  const truncatedTitle = truncate(title, 30, '...');

  return (
    <Card
      {...props}
      style={{margin: 10}}
      className={classes.root}
      elevation={0}
    >
      <CardActionArea style={{ padding: 0 }}  onClick={goToArticle}>
          <CardMedia
            className={classes.media}
            image={image || imageCard}
            title={title}
          />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.title}
          >
            {truncatedTitle}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export { ArticleCard };
