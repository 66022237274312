/* eslint-disable no-duplicate-case */
import config from "../config";
import { reportConstants } from "./../constants"
const today = new Date();
const beforeMonth = new Date().setMonth(new Date().getMonth() - 1);
const monthYear = today;

const reportType = config.SHOW_IDEAS_BY_STEP_FLUX ? 'ideas-financial-return-by-step' : 'ideas-financial-return';

const initialState = {
  requestParams: {},
  filters: {
    status: [],
    challenges: [],
    properties: [],
    personalizedClassifications: [],
    startDate: beforeMonth,
    endDate: today,
    monthYear: monthYear,
    query: "",
    ideasUngrouped: false,
    ideaDescriptionOnCsv: false,
    useConclusionDate: false,
    ideasVisibilityInFeed: false,
    bulWithoutUsers: false,
    decisions: [],
    step: ""
  },
  tab: 'table',
  report: [],
  chart: [],
  paging: {
    limit: 10,
    page: 1,
    skip: 0,
    query: '',
    sort: {
      createdAt: -1
    },
    total: 0
  },
  fieldValidation: {
    "ideas-status": {
      businessUnit: true,
      local: true,
      area: true,
      status: true,
      startDate: true,
      endDate: true,
      challenges: true,
      personalizedClassifications: true
    },
    "ideas-by-step": {
      businessUnit: true,
      local: true,
      area: true,
      status: true,
      startDate: true,
      endDate: true,
      challenges: true,
      personalizedClassifications: true
    },
    "ideas-by-evaluated-step-decision": {
        startDate: true,
        endDate: true,
        step: true,
        decisions: true,
        status: false
      },
    "ideas-status-pending-managers": {
      businessUnit: false,
      local: false,
      area: false,
      status: false,
      startDate: true,
      endDate: true,
    },
    "colaborator": {
      businessUnit: false,
      local: true,
      area: true,
      status: false,
      startDate: true,
      endDate: true,
    },
    [reportType]: {
      businessUnit: false,
      local: true,
      area: true,
      status: true,
      startDate: true,
      endDate: true,
    },
    "colaborator-access": {
      businessUnit: true,
      local: true,
      area: true,
      status: false,
      startDate: true,
      endDate: true,
    },
    "managers-list": {
      local: true,
      area: true,
      businessUnit: true,
      status: false,
      startDate: false,
      endDate: false,
      showCheckBoxEmptyBul: true
    },
    "ideas-with-evaluated-steps": {
      businessUnit: false,
      local: false,
      area: false,
      status: false,
      startDate: true,
      endDate: true,
    }
  },
  loading: false,
  csvLoading: false,
  csvBase64: "",
}

export const report = (state = initialState, action) => {
  switch (action.type) {
    case reportConstants.LOAD_PROPERTIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          properties: action.payload,
        }
      }

    case reportConstants.CHANGE_PROPERTIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          properties: state.filters.properties.map((item, index) => {
            if (index === parseInt(action.payload.index)) {
              return {
                ...item,
                checked: !item.checked
              }
            }

            return item;
          })
        }
      }

    case reportConstants.LOAD_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.payload,
        }
      }
    
    case reportConstants.LOAD_PERSONALIZED_CLASSIFICATIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          personalizedClassifications: action.payload,
        }
      }

    case reportConstants.REPORTS_LOAD_CHALLENGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          challenges: action.payload,
        }
      }

    case reportConstants.CHANGE_STATUS:
      if (action.payload.value === 'SELECT_ALL') {
        return {
          ...state,
          filters: {
            ...state.filters,
            status: state.filters.status.map(item => ({
              ...item,
              checked: action.payload.checked ? false : true
            }))
          }
        }
      } else {
        return {
          ...state,
          filters: {
            ...state.filters,
            status: state.filters.status.map(item => {
              if (item.value === action.payload.value) {
                return {
                  ...item,
                  checked: !action.payload.checked
                }
              }
              return item;
            })
          }
        }
      }

      case reportConstants.CHANGE_PERSONALIZED_CLASSIFICATIONS:
        if (action.payload.value === 'SELECT_ALL') {
          return {
            ...state,
            filters: {
              ...state.filters,
              personalizedClassifications: state.filters.personalizedClassifications.map(item => ({
                ...item,
                checked: action.payload.checked ? false : true
              }))
            }
          }
        } else {
          return {
            ...state,
            filters: {
              ...state.filters,
              personalizedClassifications: state.filters.personalizedClassifications.map(item => {
                if (item.value === action.payload.value) {
                  return {
                    ...item,
                    checked: !action.payload.checked
                  }
                }
                return item;
              })
            }
          }
        }

    case reportConstants.REPORTS_CHANGE_CHALLENGE:
      if (action.payload.value === 'SELECT_ALL') {
        return {
          ...state,
          filters: {
            ...state.filters,
            challenges: state.filters.challenges.map(item => ({
              ...item,
              checked: action.payload.checked ? false : true
            }))
          }
        }
      } else {
        return {
          ...state,
          filters: {
            ...state.filters,
            challenges: state.filters.challenges.map(item => {
              if (item.value === action.payload.value) {
                return {
                  ...item,
                  checked: !action.payload.checked
                }
              }
              return item;
            })
          }
        }
      }

    case reportConstants.CHANGE_START_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: action.payload
        }
      }

    case reportConstants.CHANGE_END_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          endDate: action.payload
        }
      }

    case reportConstants.CHANGE_BUL_EMPTY_CHECKED:
      return {
        ...state,
        filters: {
          ...state.filters,
          bulWithoutUsers: action.payload
        }
      };
    
    case reportConstants.SHOW_IDEAS_UNGROUPED:
    case reportConstants.SHOW_IDEA_DESCRIPTION_ON_CSV:
    case reportConstants.USE_CONCLUSION_DATE:
    case reportConstants.SHOW_HIDDEN_IDEAS_IN_FEED:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.checked
        }
      }

    case reportConstants.PRE_REPORT_DATA_REQUEST: {
      return {
        ...state,
        report: [],
        chart: [],
      }
    }

    case reportConstants.REPORT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case reportConstants.REPORT_DATA_SUCCESS:
      return {
        ...state,
        paging: action.payload.paging,
        report: action.payload.report,
        csvBase64: false,
        loading: false,
      }

    case reportConstants.REPORT_DATA_CHART_SUCCESS:
      return {
        ...state,
        chart: action.payload,
        loading: false,
      }

    case reportConstants.REPORT_DATA_FAILURE:
      return {
        ...state,
        loading: false
      }

    case reportConstants.CLEAR_FILTERS:
      return {
        ...state,
        filters: {
          ...initialState.filters,
          challenges: state.filters.challenges,
          status: state.filters.status,
          properties: state.filters.properties,
          personalizedClassifications: state.filters.personalizedClassifications,
          startDate: beforeMonth,
          endDate: today,
          ideasUngrouped: initialState.filters.ideasUngrouped,
          ideaDescriptionOnCsv: initialState.filters.ideaDescriptionOnCsv,
          useConclusionDate: initialState.filters.useConclusionDate,
          ideasVisibilityInFeed: initialState.filters.ideasVisibilityInFeed,
          bulWithoutUsers: initialState.filters.bulWithoutUsers,     
          monthYear: initialState.filters.monthYear
        }
      };

    case reportConstants.CLEAR_DECISIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: state.filters.status.map(item => ({
            ...item,
            checked: false,
          }))
        }
      }

    case reportConstants.CLEAR_PERSONALIZED_CLASSIFICATIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          personalizedClassifications: state.filters.personalizedClassifications.map(item => ({
            ...item,
            checked: false,
          }))
        }
      }

    case reportConstants.REPORTS_CLEAR_CHALLENGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          challenges: state.filters.challenges.map(item => ({
            ...item,
            checked: false,
          }))
        }
      }

    case reportConstants.CLEAR_ALL_REPORT:
      return initialState

    case reportConstants.GET_REPORT_CSV_REQUEST:
      return {
        ...state,
        csvLoading: true
      }

    case reportConstants.GET_REPORT_CSV_SUCCESS:
      return {
        ...state,
        csvLoading: false,
        csvBase64: action.payload
      }

    case reportConstants.GET_REPORT_CSV_FAILURE:
      return {
        ...state,
        csvLoading: false,
        error: "Não foi possível gerar CSV"
      }

    case reportConstants.SER_REPORT_TAB:
      return {
        ...state,
        tab: action.payload
      }

    case reportConstants.CLEAR_REPORT_SEARCH: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: ''
        }
      }
    }

    case reportConstants.CHANGE_REPORT_SEARCH: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload
        }
      }
    }

    
    case reportConstants.CHANGE_MONTH_YEAR_DATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          monthYear: action.payload
        }
      }

    
    case reportConstants.CHANGE_STEP: 
      return {
        ...state,
        filters: {
          ...state.filters,
          step: action.payload
        }
      }

   case reportConstants.CLEAR_STEP: 
      return {
        ...state,
        filters: {
          ...state.filters,
          step: ""
        }
      }

 case reportConstants.SET_DECISIONS:
      if (action.payload.value === 'SELECT_ALL') {
        return {
          ...state,
          filters: {
            ...state.filters,
            decisions: state.filters.decisions.map(item => ({
              ...item,
              checked: action.payload.checked ? false : true
            }))
          }
        }
      } else {
        return {
          ...state,
          filters: {
            ...state.filters,
            decisions: state.filters.decisions.map(item => {
              if (item.value === action.payload.value) {
                return {
                  ...item,
                  checked: !action.payload.checked
                }
              }
              return item;
            })
          }
        }
      }

  case reportConstants.LOAD_DECISIONS:
    return {
      ...state,
      filters: {
        ...state.filters,
        decisions: action.payload
      }
    }

 case reportConstants.CLEAR_DECISIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          decisions: state.filters.decisions.map(item => ({
            ...item,
            checked: false,
          }))
        }
      }

    case reportConstants.SET_REQUEST_PARAMS:
      return {
        ...state,
        requestParams: action.payload
      }

    default:
      return state;
  }
}