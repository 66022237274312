import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Grid, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';

import { fluxOfIdeasActions } from '../../../actions';
import { AvatarIconWithText } from '../../Shared';
import { Card } from './styles';
import { useIdeaDetailsController } from '../../../containers/IdeaDetailsPage';

export function ResourcesCard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ideaDetailsFlux } = useIdeaDetailsController();

  function handleIdeaInFeed(checked) {
    dispatch(fluxOfIdeasActions.ideaVisibleInTimeline(ideaDetailsFlux._id, checked));
  }

  return (
    ideaDetailsFlux?.statusIdea?.code === 'DRAFT' ||
    ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?.optionIdeiaInTimeline && (
      <Card elevation={0}>
        <Grid container spacing={3} style={{ padding: '32px 32px 20px' }}>
          <Grid item xs={12}>
            <AvatarIconWithText
              icon={<TuneIcon />}
              text={t('recursos')}
              variant="h3"
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                label={t('publicar_ideia_no_feed')}
                control={
                  <Switch
                    color="primary"
                    checked={ideaDetailsFlux?.timeline}
                    onChange={() => handleIdeaInFeed(!ideaDetailsFlux?.timeline)}
                  />
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card>
    )
  );
}