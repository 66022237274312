import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@material-ui/core';

import { HistoryCard } from './HistoryCard';
import { Card } from './styles';
import { EmptyState } from '../../Shared';
import { generalEvaluationIcon }  from '../../../assets/imgs';
import { useIdeaDetailsController } from '../../../containers/IdeaDetailsPage/hooks/useIdeaDetailsController';
import { HistoryCardInitial } from './HistoryCardInitial';
import { IdeaQualification } from '../../Idea';

export function HistoryLayout() {
  const { t } = useTranslation();
  const { ideaDetailsFlux } = useIdeaDetailsController();
  const hasHistory = ideaDetailsFlux?.evaluationStepsHistory?.length > 0;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          {t('historico_antigo').toLocaleUpperCase()}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <IdeaQualification
          idea={ideaDetailsFlux}
          createdAt={ideaDetailsFlux?.createdAt}
          approveDate={ideaDetailsFlux?.approveDate}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          {t('historico_atual').toLocaleUpperCase()}
        </Typography>
      </Grid>
      
       {hasHistory ? (
        <>
          <Grid item xs={12}>
            <HistoryCardInitial />
          </Grid>
          <Grid item xs={12}>
            <HistoryCard />
          </Grid>
        </>
        ) : (
          <Card elevation={0} style={{ padding: 32 }}>
            <EmptyState
              icon={generalEvaluationIcon}
              title={t('ainda_nao_ha_um_historico_para_mostrar')}
              description={t('os_eventos_serao_exibidos_aqui_quando_as_ideias_forem_avaliadas')}
            />
          </Card>
        )}
    </Grid>
  );
}