import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const contentsService = {
  getFixedContent,
  getCotents,
  getCotentsByStatus
};

function getFixedContent() {
  return axios.get(`${config.URL_BASE}/contents/fixed`, requestHeaders())
    .then(handleResponse);
}

async function getCotents(page, sort, limit, searchText) {
  const isSearch = searchText
    ? `contents/?page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}&searchText=${searchText}`
    : `contents/?page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`;
  const { data } = await axios.get(`${config.URL_BASE}/${isSearch}`, requestHeaders())
  return data;
}

async function getCotentsByStatus(page, sort, limit, status) {
  const { data } = await axios.get(`${config.URL_BASE}/contents/status/${status}?page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`, requestHeaders());
  return data;
}