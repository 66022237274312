export const externalContentsConstants = {
  GET_EXTERNAL_CONTENT_LIST_REQUEST: 'GET_EXTERNAL_CONTENT_LIST_REQUEST',
  GET_EXTERNAL_CONTENT_LIST_SUCCESS: 'GET_EXTERNAL_CONTENT_LIST_SUCCESS',
  GET_EXTERNAL_CONTENT_LIST_FAILURE: 'GET_EXTERNAL_CONTENT_LIST_FAILURE',

  TOGGLE_FILTER: 'TOGGLE_FILTER',

  SET_EXTERNAL_CONTENT_LOADING: 'SET_EXTERNAL_CONTENT_LOADING',
  SET_EXTERNAL_CONTENT_DATA: 'SET_EXTERNAL_CONTENT_DATA',
  SET_NEW_CONTENT: "SET_NEW_CONTENT",
  SET_NEW_CONTENT_SWITCH: "SET_NEW_CONTENT_SWITCH",
  CLEAR_CONTENT_FIELDS: 'CLEAR_CONTENT_FIELDS',

  EXTERNAL_CONTENT_CLEAR_ALL: 'EXTERNAL_CONTENT_CLEAR_ALL',

  GET_ALL_EXTERNAL_CONTENTS_REQUEST: 'GET_ALL_EXTERNAL_CONTENTS_REQUEST',
  GET_ALL_EXTERNAL_CONTENTS_SUCCESS: 'GET_ALL_EXTERNAL_CONTENTS_SUCCESS',
  GET_ALL_EXTERNAL_CONTENTS_FAILURE: 'GET_ALL_EXTERNAL_CONTENTS_FAILURE',

  GET_EXTERNAL_CONTENTS_BY_STATUS_REQUEST: 'GET_EXTERNAL_CONTENTS_BY_STATUS_REQUEST',
  GET_EXTERNAL_CONTENTS_BY_STATUS_SUCCESS: 'GET_EXTERNAL_CONTENTS_BY_STATUS_SUCCESS',
  GET_EXTERNAL_CONTENTS_BY_STATUS_FAILURE: 'GET_EXTERNAL_CONTENTS_BY_STATUS_FAILURE'
}