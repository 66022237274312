import { authenticationService, userService } from '../services';
import { userActions } from '../actions';
import { authenticationConstanst } from '../constants';
import { history } from '../helpers';
import { domainsActions } from './domains.actions';
import { alertActions } from './alert.actions';

export const authenticationActions = {
  login,
  register,
  registerOffice365,
  loginOffice365,
  loginGoogle,
  logout,
  logout365,
};

function login(email, password, errorMessage) {
  return (dispatch) => {
    dispatch(request());

    authenticationService.login(email, password).then(
      (response) => {
        dispatch(success(response.token));
        dispatch(userActions.getUser('authentication'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  function request() {
    return { type: authenticationConstanst.LOGIN_REQUEST };
  }
  function success(token) {
    return { type: authenticationConstanst.LOGIN_SUCCESS, token };
  }
  function failure(error) {
    return { type: authenticationConstanst.LOGIN_FAILURE, error };
  }
}
function loginGoogle(token) {
  return (dispatch) => {
    dispatch(request());

    authenticationService.loginGoogle(token).then(
      (token) => {
        dispatch(success(token));
        userService.getUser().then((user) => {
          if (user.isFullRegister) {
            dispatch(userActions.getUser('authentication'));
          } else {
            dispatch(domainsActions.getDomains());
            dispatch(userActions.getUser('authentication'));
            // history.push('/register');
          }
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: authenticationConstanst.LOGIN_OFFICE_REQUEST
    };
  }

  function success(token) {
    const payload = {
      token,
    };
    return {
      type: authenticationConstanst.LOGIN_OFFICE_SUCCESS,
      payload
    };
  }

  function failure(error) {
    return {
      type: authenticationConstanst.LOGIN_OFFICE_FAILURE,
      error
    };
  }
}
function loginOffice365(token) {
  return (dispatch) => {
    dispatch(request());

    authenticationService.loginOffice365(token).then(
      (token) => {
        dispatch(success(token));
        userService.getUser().then((user) => {
          if (user.isFullRegister) {
            dispatch(userActions.getUser('authentication'));
          } else {
            dispatch(domainsActions.getDomains());
            dispatch(userActions.getUser('authentication'));
            // history.push('/register');
          }
        });
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: authenticationConstanst.LOGIN_OFFICE_REQUEST };
  }

  function success(token) {
    const payload = {
      token,
    };
    return { type: authenticationConstanst.LOGIN_OFFICE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: authenticationConstanst.LOGIN_OFFICE_FAILURE, error };
  }
}

function registerOffice365(user) {
  return (dispatch) => {
    authenticationService.registerOffice365(user).then(
      () => {
        dispatch(success());
        dispatch(userActions.getUser('authentication'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success() {
    return { type: authenticationConstanst.REGISTER_OFFICE_SUCCESS };
  }

  function failure(error) {
    return { type: authenticationConstanst.REGISTER_OFFICE_FAILURE, error };
  }
}

/*
function registerGoogle(user) {
  return (dispatch) => {
    authenticationService.registerGoogle(user).then(
      () => {
        dispatch(success());
        dispatch(userActions.getUser('authentication'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function success() {
    return {
      type: authenticationConstanst.REGISTER_OFFICE_SUCCESS
    };
  }

  function failure(error) {
    return {
      type: authenticationConstanst.REGISTER_OFFICE_FAILURE,
      error
    };
  }
}
*/

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    authenticationService.register(user).then(
      (response) => {
        dispatch(success());
        dispatch(login(response.token));
        history.push('/');
      },
      (error) => {
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: authenticationConstanst.REGISTER_REQUEST, user };
  }
  function success() {
    return { type: authenticationConstanst.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: authenticationConstanst.REGISTER_FAILURE, error };
  }
  function login(token) {
    return { type: authenticationConstanst.LOGIN_SUCCESS, token };
  }
}

function logout() {
  return (dispatch) => {
    authenticationService.logout();
    dispatch(logout());
    history.push('/login');

    function logout() {
      return { type: authenticationConstanst.LOGOUT };
    }
  };
}
function logout365() {
  return (dispatch) => {
    authenticationService.logout();
    dispatch(logout());
    window.location.href='https://login.microsoftonline.com/logout.srf'
    function logout() {
      return { type: authenticationConstanst.LOGOUT };
    }
  };
}
