import React from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TablePagination,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from '@material-ui/core';

import { StatusIdea } from '../../components';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TurnedInIcon from '@material-ui/icons/TurnedIn';

import { tableCss } from './TableCss';
import { truncate } from '../../helpers';

import { useDispatch } from 'react-redux';
import { externalContentActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { TableMenu } from './TableMenu';
import { ImagineLinkify } from '../TextLinkify';

function ExternalContentsTable({ externalContents, paging, history, dialog, setExternalContent, status }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const tableClasses = tableCss({
    text: t('linhas_por_pagina'),
  });

  const columns = [
    { id: 'title', label: t('titulo') },
    { id: 'link', label: t('url') },
    { id: 'status', label: t('status') },
    { id: 'actions', label: t('fixado'), align: 'right' },
    { id: 'highlight', label: '' }
  ];
  
  function openDialogDelete(item) {
    dialog.setOpenDialog(true);
    setExternalContent(item);
  }

  function handleEdit({ _id }) {
    history.push(`/external-content/edit/${_id}`);
  }

  function handleChangePage(_event, page) {
    let newPage = page + 1;

    if (status === 'all') {
      dispatch(externalContentActions.getAllContents(
        newPage,
        paging.sort,
        paging.limit,
        paging.search
      ));
    } else {
      dispatch(externalContentActions.getContentsByStatus(
        newPage,
        paging.sort,
        paging.limit,
        (status === 'active' && true || status === 'disabled' && false)
      ));
    }
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;

    if (status === 'all') {
      dispatch(externalContentActions.getAllContents(
        1,
        paging.sort,
        limit,
        paging.search
      ));
    } else {
      dispatch(externalContentActions.getContentsByStatus(
        1,
        paging.sort,
        limit,
        (status === 'active' && true || status === 'disabled' && false)
      ));
    }
  }

  return (
    <Paper className={tableClasses.paperRoot}>
      <TableContainer sx={{ position: 'relative' }}>
        <Table
          stickyHeader
          aria-label="external content table"
          classes={{ root: tableClasses.table }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  classes={{ root: tableClasses.tableCellHeader }}
                  align={column.align || 'left'}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {externalContents &&
              externalContents.map((item) => {
                const link = item.link.length < 60
                  ? item.link
                  : item.link.substr(0, 60) + '...';

                return (
                  <TableRow
                    hover
                    key={item._id}
                    style={{ cursor: 'pointer', height: 60 }}
                    classes={{ root: tableClasses.tableRow }}
                  >
                    <TableCell
                      classes={{ root: tableClasses.tableCellRadiusLeft }}
                    >
                      {truncate(item.title, 50)}
                    </TableCell>
                    <TableCell
                      className={tableClasses.oneLine}
                      classes={{ root: tableClasses.tableCell }}
                    >
                      <ImagineLinkify>{link}</ImagineLinkify>
                    </TableCell>
                    <TableCell classes={{ root: tableClasses.tableCell }}>
                      <StatusIdea
                        status={{
                          name: item.status ? 'ativo' : 'não ativo',
                          color: item.status ? theme.palette.secondary.dark : theme.palette.auxiliary.orange.main
                        }}
                      />
                    </TableCell>
                    <TableCell classes={{ root: tableClasses.tableCell }}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          paddingRight: 8,
                        }}
                      >
                        {!!item.fixed && (
                          <TurnedInIcon color="primary" />
                        )}
                      </Box>
                    </TableCell>
                    <TableMenu>
                      <List>
                        <ListItem onClick={() => handleEdit(item)} button>
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText primary={t('editar')} />
                        </ListItem>
                      </List>
                      <List>
                        <ListItem onClick={() => openDialogDelete(item)} button>
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText primary={t('delete')} />
                        </ListItem>
                      </List>
                    </TableMenu>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        classes={{
          input: tableClasses.paginationInput,
          spacer: tableClasses.paginationSpacer,
          caption: tableClasses.paginationCaption,
          toolbar: tableClasses.paginationToolbar,
        }}
        rowsPerPageOptions={[10, 20, 30, 40, 50]}
        count={paging.total}
        rowsPerPage={paging.limit}
        page={paging.page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('exibindo')}
        style={{ zIndex: 10, position: 'relative' }}
      />
    </Paper>
  );
}

export { ExternalContentsTable };