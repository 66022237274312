import React from 'react';
import { MenuItem, Select, InputLabel, FormControl } from '@material-ui/core';

export function ImagineFormSelect({ label = '', name = '', isRequired = false, options = [{ label: '', value: '' }], onChange = () => { } }) {
  const MenuProps = {
    PaperProps: {
      style: {
        width: 200,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel shrink={true} id={name}>{label}</InputLabel>
      <Select
        labelId={name}
        name={name}
        required={isRequired}
        onChange={({ target: value }) => {onChange(value)}}
        MenuProps={MenuProps}
      >
        {options.map(({ label, value }, index) => (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}