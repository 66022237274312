import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TableCell, IconButton, Typography } from "@material-ui/core";
import ViewIcon from '@material-ui/icons/Visibility';
import config from '../../config';
import { history } from '../../helpers';
import { useWindowSize } from '../../hooks';
import { reportActions } from "../../actions";
import { tableCss } from '../../components/Tables/TableCss';
import { TablePanel, ReportCards, ReportLayout } from "../../components";
import { CustomTooltip } from '../../components/Shared';
import { formatDate } from '../../utils/date';

const getPersonalizedClassificationsNames = (personalizedClassifications) => {
  if (personalizedClassifications && personalizedClassifications.length > 0) {
    const personalizedClassificationsNames = personalizedClassifications.map((pc) => pc.name);
    return personalizedClassificationsNames.toString();
  }
  return '';
};

export const ReportIdeasByStepDecision = () => {
  const dispatch = useDispatch();
  const tableClasses = tableCss();
  const [arrItensCard, setArrItensCArd] = useState({
    cardContent: [],
    dialogContent: []
  })
  const [width] = useWindowSize();
  const { report, filters, paging } = useSelector(state => state.report);
  const { t } = useTranslation();

  function formatBRL(value) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
      .format(parseFloat(value).toFixed(2))
  }

  function handleView(id) {
    window.open(`/idea/${id}/general`);
  }
  function handleSelectedColumnChecked(columnName) {
    return filters.properties.filter(({ value }) => value === columnName)[0]?.checked;
  }

  const tableInfo = {
    columns: [
      { id: "code", label: t('codigo') },
      { id: 'nomeautor', label: t("autor") },
      { id: 'ideaName', label: t('nome_da_ideia') },
      { id: 'etapas', label: t('etapas') },
      { id: 'step_date', label: t('data_da_etapa') },
      { id: 'businessUnit', label: t('unidade_de_negocio') },
      { id: 'local', label: t('local') },
      { id: 'area', label: t('area') },
      { id: 'tipoAmbito', label: t('tipo_ambito') },
      { id: 'classificacaoPersonalizada', label: t('classificacao_personalizada') },
      { id: 'date', label: t('data') + ' ' + t('inicio') },
      { id: 'conclusionDate', label: t('data') + ' ' + t('termino') },
      { id: "retorno_estimado", label: t("retorno_estimado") },
      { id: "custo_estimado", label: t("custo_estimado") },
      { id: 'link', label: t('link') },
    ],
    data: report.map(item => (
      <Fragment>
        {handleSelectedColumnChecked('code') && <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>{item.code || ""}</TableCell>}
        {handleSelectedColumnChecked('nomeautor') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.userName || ""}</TableCell>}
        {handleSelectedColumnChecked('ideaName') && <TableCell classes={{ root: tableClasses.tableCell }}>
          <CustomTooltip title={item.name} aria-label={item.name} placement="bottom-start">
            <Typography variant="subtitle2" className={tableClasses.link} onClick={() => handleView(item._id)}>{item.name}</Typography>
          </CustomTooltip>
        </TableCell>}
        {handleSelectedColumnChecked('etapas') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.statusIdea?.name || item.currentStepFlux?.ideaStepFlux?.name}</TableCell>}
        {handleSelectedColumnChecked('step_date') && <TableCell classes={{ root: tableClasses.tableCell }}>{`${moment(item.currentStepFlux?.createdAt).format('DD/MM/YYYY')}`}</TableCell>}
        {handleSelectedColumnChecked('businessUnit') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.businessUnit?.name : ""}</TableCell>}
        {handleSelectedColumnChecked('local') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.local?.name : ""}</TableCell>}
        {handleSelectedColumnChecked('area') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.occupationArea?.name : ""}</TableCell>}
        {handleSelectedColumnChecked('tipoAmbito') && <TableCell classes={{ root: tableClasses.tableCell }}>{item.methodology?.name}</TableCell>}
        {handleSelectedColumnChecked('classificacaoPersonalizada') && <TableCell classes={{ root: tableClasses.tableCell }}>{getPersonalizedClassificationsNames(item.personalizedClassifications)}</TableCell>}
        {handleSelectedColumnChecked('date') && <TableCell classes={{ root: tableClasses.tableCell }}>{`${moment(item.createdAt).format('DD/MM/YYYY')}`}</TableCell>}
        {handleSelectedColumnChecked('conclusionDate') && <TableCell classes={{ root: tableClasses.tableCell }}>{item?.conclusionDate ? `${moment(item?.conclusionDate).format('DD/MM/YYYY')}` : ''}</TableCell>}
        {handleSelectedColumnChecked('retorno_estimado') && <TableCell classes={{ root: tableClasses.tableCell }}>{formatBRL(item.estimatedFinancialReturn
          || 0)}</TableCell>}
        {handleSelectedColumnChecked('custo_estimado') && <TableCell classes={{ root: tableClasses.tableCell }}>{formatBRL(item.estimatedExecutionCost || 0)}</TableCell>}
        {handleSelectedColumnChecked('link') && <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>
          <IconButton onClick={() => { history.push(`/idea/${item._id}/general`) }}>
            <ViewIcon />
          </IconButton>
        </TableCell>}
      </Fragment>
    )),
  }

  useEffect(() => {
    setArrItensCArd({
      cardContent: report.map(item => (
        [
          {
            name: "nome da ideia",
            value: item.name,
          },
          {
            name: 'etapas',
            value: item.statusIdea?.name || item.currentStepFlux?.name
          },
          {
            name: "local",
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.local?.name : ""
          }
        ]
      )),
      dialogContent: report.map(item => (
        [
          {
            name: "code",
            value: item.code,
          },
          {
            name: t("autor"),
            value: item.userName,
          },
          {
            name: "nome da ideia",
            value: item.name,
          },
          {
            name: 'etapas',
            value: item.statusIdea?.name || item.currentStepFlux?.name
          },

          {
            name: t('unidade_de_negocio'),
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.businessUnit?.name : ""
          },
          {
            name: "local",
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.local?.name : ""
          },
          {
            name: "area",
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.occupationArea?.name : ""
          },
          {
            name: "data",
            value: moment(item.createAt).format('DD/MM/YYYY')
          },
          {
            name: "tipo / âmbito",
            value: item.methodology?.name
          },
          {
            name: "classificação personalizada",
            value: getPersonalizedClassificationsNames(item.personalizedClassifications)
          },
          {
            name: "data de conclusão",
            value: moment(item?.conclusionDate).format('DD/MM/YYYY')
          },
          {
            name: t("retorno_estimado"),
            value: item.retornoEstimado,
          },
          {
            name: t("custo_estimado"),
            value: item.custoEstimado,
          },
          {
            name: t("link"),
          },
        ]
      ))
    })
  }, [report])

  useEffect(() => {
    dispatch(reportActions.setRequestParams({
    step: filters.step,
    decisions: filters.decisions.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
    startDate: formatDate(filters.startDate),
    endDate: formatDate(filters.endDate),
    reportType: "ideas-by-evaluated-step-decision",
    page: paging.page,
    limit: paging.limit,
    sort: { "evaluationStepsHistory.evaluatedAt": -1 }
  }))
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(reportActions.clearAll())
    }
  }, [dispatch])

  return (
    <ReportLayout title={t('etapas_das_ideias')} tableInfo={tableInfo.columns}>
      <Fragment>
        {width > config.RESPONSIVE_MOBILE ?
          <TablePanel tableInfo={tableInfo} /> :
          <ReportCards arrItems={arrItensCard} />
        }
      </Fragment>
    </ReportLayout>
  )
}