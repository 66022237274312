import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Button, Card } from "@material-ui/core"
import { ideaEditStyles } from "./IdeaEditStyles"
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector, useDispatch } from 'react-redux'
import { ideaUpdateActions } from "./../../actions"
import { useParams } from 'react-router-dom';
import { DialogDeleteDraft } from '../../components';
import { Skeleton } from '@material-ui/lab';
import config from '../../config';

export const IdeaEditActions = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = ideaEditStyles();
  const { ideaUpdate } = useSelector(state => state);
  const { deleteLoading } = useSelector(state => state.idea);
  const { fields } = useSelector(state => state.multiAreaCascade);
  const { filesToRemove, saveLoading } = useSelector(state => state.ideaUpdate);
  const [ deleteDialog, setDeleteDialog ] = useState(false);

  function convertStringInNumber(value) {

    if (typeof value === 'string') {
      let cleanValue = value.replace('R$', ' ').trim();
  
      cleanValue = cleanValue.replace(/\./g, '').replace(',', '.');
  
      let number = parseFloat(cleanValue);
  
      return number;
    }

    return value;

  }

  function handleSave() {
    if (filesToRemove.length > 0) {
      dispatch(ideaUpdateActions.sendRemoveFiles(id, filesToRemove));
    }

    let data = {
      ...ideaUpdate,
      contributors: ideaUpdate.contributors.map(item => item.value),
      estimatedFinancialReturn: convertStringInNumber(ideaUpdate.estimatedFinancialReturn),
      estimatedExecutionCost: convertStringInNumber(ideaUpdate.estimatedExecutionCost),
      challenge: ideaUpdate.challenge?.value || null,
      businessUnitLocal: fields.filter(item => item.selectedBusinessUnit).map(item => item.selectedBusinessUnit),
      personalizedClassifications: ideaUpdate.personalizedClassifications.filter(item => item.checked === true).map(item => {
        delete item.checked;
        return item;
      })
    }

    if (!config.SHOW_PERSONALIZED_EVALUATION_ON_IDEA) {
      delete data.personalizedClassifications;
      delete data.classificationsList;
    }

    dispatch(ideaUpdateActions.updateIdea(id, data, t('sua_ideia_foi_salva')));
  }

  function toggleDeleteDialog() {
    setDeleteDialog(!deleteDialog)
  }

  function SkeletonCard() {
    return (
      <Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 50 }}>
        <Skeleton width='90%' height={80} />
        <Skeleton width='90%' height={80} />
      </Card>
    );
  }

  return (
    <>
      <DialogDeleteDraft open={deleteDialog} idea={ideaUpdate} onClose={toggleDeleteDialog} />
      {ideaUpdate?._id ? (
        <Grid item container spacing={2} className={`${classes.root} ${classes.sticky}`}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              className={classes.actionButton}
              onClick={handleSave}
              disabled={deleteLoading || saveLoading}
            >
              {t('salvar_ideia')}
            </Button>
          </Grid>
          {(ideaUpdate?.statusIdea?.code === 'AWAITING_QUALIFICATION') && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<DeleteIcon />}
                className={classes.actionButton}
                onClick={toggleDeleteDialog}
                disabled={deleteLoading || saveLoading}
              >
                {t('excluir_ideia')}
              </Button>
            </Grid>
          )}
        </Grid>
      ) : <SkeletonCard />}
    </>
  )
}
