import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogContent, DialogActions } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useCreateIdeaController } from './hooks/useCreateIdeaController';
import { DialogCustomTitle } from './DialogCustomTitle';
import { DialogCreateIdea } from './DialogCreateIdea';
import { ReviewIdeaContent } from './ReviewIdea';
import { LoadingButton } from '../Shared';
import { dialogCss } from './dialogCss';

export function DialogSendIdea() {
  const classes = dialogCss();
  const { t } = useTranslation();
  const {
    dialogSendIdea,
    loadingCreateIdea,
    createIdea,
    createDraft,
    close,
    previous
  } = useCreateIdeaController();

  return (
    <DialogCreateIdea open={dialogSendIdea}>
      <DialogCustomTitle goBack={previous} onClose={close}>
        {t('revisao')}
      </DialogCustomTitle>

      <DialogContent dividers className={classes.dividerBottom}>
        <ReviewIdeaContent />
      </DialogContent>

      <DialogActions className={classes.containerAction}>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          {loadingCreateIdea && <LoadingButton />}

          {!loadingCreateIdea && (
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              style={{ marginRight: 8 }}
              onClick={createDraft}
            >
              {t('salvar_rascunho')}
            </Button>
          )}

          {!loadingCreateIdea && (
            <Button
              className={classes.button}
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
              onClick={createIdea}
            >
              {t('submeter_ideia')}
            </Button>
          )}
        </Box>
      </DialogActions>
    </DialogCreateIdea>
  );
}