import React, { useState } from 'react';
import {
  CardContent,
  TextField,
  makeStyles,
} from '@material-ui/core';

import { articleActions } from '../../actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Description } from '@material-ui/icons';
import { AvatarIconWithText } from '../Shared';
import { Card } from './articlesStyles';

const useStyles = makeStyles((theme) => ({
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  about: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.66,
  },
  content: {
    padding: theme.spacing(2, 0),
  }
}));

function ArticleCreateCard({ title, description, isEdit=false }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [descriptionChar, setDescription] = useState('');
  const { t } = useTranslation();
  const { register, errors, getValues } = useForm({
    mode: 'onBlur',
  });

  function handleDescription() {
    const description = getValues().description;
    setDescription(description);
  }

  function setPropArticle({ target: { value, name } }) {
    dispatch(articleActions.setPropArticle(value, name, isEdit));
  }

  return (
    <Card elevation={0}>
      <AvatarIconWithText
        icon={<Description color="primary" />}
        text={t('artigo')}
      />
      <CardContent className={classes.content}>
        <TextField
          name="title"
          id="title"
          label={t('titulo_do_artigo')}
          autoComplete="title"
          type="text"
          variant="filled"
          margin="normal"
          fullWidth
          inputRef={register({
            required: t('titulo_do_artigo_e_obrigatorio'),
          })}
          error={!!errors.title}
          helperText={errors.title && errors.title.message}
          className={classes.marginBetweenInputs}
          InputLabelProps={{
            classes: {
              root: classes.labelRoot,
            },
          }}
          defaultValue={title}
          onBlur={setPropArticle}
        />

        <div className={clsx(classes.about, classes.marginBetweenInputs)}>
          <div className={classes.characters}>
            <span>{descriptionChar.length}/10000 caracteres</span>
          </div>
          <TextField
            error={!!errors.description}
            id="outlined-multiline-static"
            name="description"
            label={t('descricao_do_artigo')}
            multiline
            fullWidth
            minRows={8}
            variant="filled"
            onChange={handleDescription}
            inputRef={register({
              maxLength: 10000,
              minLength: 100,
              required: t('descricao_do_artigo_e_obrigatorio'),
            })}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
            defaultValue={description}
            onBlur={setPropArticle}
          />
          <p className={classes.error}>
            {errors.description && errors.description.message}
            {errors.description &&
              errors.description.type === 'maxLength' &&
              t('voce_passou_o_limite_de_caracteres')}
            {errors.description &&
              errors.description.type === 'minLength' &&
              t('a_quantidade_minima_de_caracteres_da_descricao_e') +
                ' 100'}
          </p>
        </div>
      </CardContent>
    </Card>
  );
}

export { ArticleCreateCard };
