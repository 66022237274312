import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  makeStyles,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Box,
} from '@material-ui/core';
import { tableCss } from './TableCss';
import { dateFormat, truncate } from '../../helpers';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { StatusIdea, StepIdea } from '../Shared';
import ViewIcon from '@material-ui/icons/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { challengeActions } from '../../actions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableMenu } from './TableMenu';
import { history } from '../../helpers';
import { CustomTooltip } from '../Shared';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    color: theme.palette.neutrals.low.medium,
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    margin: theme.spacing(3, 0),
  },
  icon: { color: theme.palette.grey[400], marginRight: 10 },
}));

function ChallengeIdeaTable({ ideas, paging, id, userRule }) {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);

  const linhasTable = {
    text: t('linhas_por_pagina'),
  };
  const tableClasses = tableCss(linhasTable);

  const columns = [
    { id: 'date', label: t('data') },
    { id: 'title', label: t('ideia') },
    { id: 'contributor', label: t('colaborador') },
    { id: 'area', label: t('area') },
    { id: 'engagement', label: t('engajamento') },
    { id: 'comments', label: t('comentarios') },
    { id: 'etapa', label: t('etapas') },
    { id: 'actions', label: '' },
  ];

  function handleChangePage(event, page) {
    let newPage = page + 1;
    dispatch(
      challengeActions.getIdeasChallenge(
        id,
        newPage,
        paging.sort,
        paging.limit,
        paging.statusCode
      )
    );
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(
      challengeActions.getIdeasChallenge(
        id,
        1,
        paging.sort,
        limit,
        paging.statusCode
      )
    );
  }

  function goToPage(idea) {
    if (userRule === 'manager') {
      switch (idea.statusIdea.code) {
        case 'AWAITING_QUALIFICATION':
          return history.push(`/idea/${idea._id}/qualification`);

        case 'EXECUTION_QUEUE':
          return history.push(`/idea/${idea._id}/execution`);

        case 'EXECUTING':
          return history.push(`/idea/${idea._id}/conclusion`);

        default:
          return history.push(`/idea/${idea._id}/general`);
      }
    } else {
      return history.push(`/idea/${idea._id}/general`);
    }
  }

  return (
    <>
      <Box className={classes.title}>{t('ideais_do_desafio')}</Box>
      <Box className={classes.root} display='flex'>
        <Paper className={tableClasses.paperRoot}>
          <TableContainer sx={{ position: "relative"}}>
            <Table
              stickyHeader
              aria-label="tabela de ideias do desafio"
              classes={{ root: tableClasses.table }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      classes={{ root: tableClasses.tableCellHeader }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ideas &&
                  ideas.length > 0 &&
                  ideas.map((idea) => {
                    return (
                      <TableRow
                        hover
                        key={idea._id}
                        classes={{ root: tableClasses.tableRow }}
                        style={{ height: 60 }}
                      >
                        <TableCell
                          classes={{ root: tableClasses.tableCellRadiusLeft }}
                        >
                          {dateFormat(idea.createdAt, language)}
                        </TableCell>
                        <TableCell
                          className={tableClasses.oneLine}
                          classes={{ root: tableClasses.tableCell }}
                        >
                          <CustomTooltip 
                            title={idea.name} 
                            aria-label={idea.name} 
                            placement="bottom-start"
                          >
                            <Typography 
                              variant="subtitle2" 
                              className={tableClasses.link} 
                              onClick={() => goToPage(idea)}
                            >
                              {truncate(idea.name, 20)}
                            </Typography>
                          </CustomTooltip>
                        </TableCell>
                        <TableCell
                          className={tableClasses.oneLine}
                          classes={{ root: tableClasses.tableCell }}
                        >
                          {truncate(idea.user?.name, 18)}
                        </TableCell>
                        <TableCell
                          className={tableClasses.oneLine}
                          classes={{ root: tableClasses.tableCell }}
                        >
                          {idea.businessUnitLocal && idea.businessUnitLocal.length > 0 && truncate(idea?.businessUnitLocal[0]?.local?.name, 15)}
                        </TableCell>
                        <TableCell
                          className={tableClasses.oneLine}
                          classes={{ root: tableClasses.tableCell }}
                        >
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <ThumbUpIcon className={classes.icon} />{' '}
                            <Box>{idea.upsLength || 0} {t('up')}s</Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          className={tableClasses.oneLine}
                          classes={{ root: tableClasses.tableCell }}
                        >
                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <ChatBubbleIcon className={classes.icon} />{' '}
                            <Box>{idea.commentsLength || 0} comentários</Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          className={tableClasses.oneLine}
                          classes={{ root: tableClasses.tableCell }}
                        >
                          {idea.currentStepFlux?.ideaStepFlux?.name && (
                            <StepIdea step={idea.currentStepFlux.ideaStepFlux} />
                          )}
                        </TableCell>
                        <TableMenu>
                          <List>
                            <ListItem onClick={() => goToPage(idea)} button>
                              <ListItemIcon>
                                <ViewIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={<Typography>
                                  {t('visualizar')}
                                </Typography>
                                }
                              />
                            </ListItem>
                          </List>
                        </TableMenu>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            classes={{
              input: tableClasses.paginationInput,
              spacer: tableClasses.paginationSpacer,
              caption: tableClasses.paginationCaption,
              toolbar: tableClasses.paginationToolbar,
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            count={paging.total}
            rowsPerPage={paging.limit}
            page={paging.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('exibindo')}
            style={{zIndex: 10, position: 'relative'}}
          />
        </Paper>
      </Box>
    </>
  );
}

export { ChallengeIdeaTable };
