import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Checkbox,
  FormLabel,
  Typography,
  makeStyles,
  withStyles,
  FormControl,
  DialogContent,
  FormControlLabel,
  MenuItem,
} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { 
  ideaCreateActions, 
  multiAreaCascadeActions,
  ideaCreateDialogsActions,
  personalizedClassificationActions,
} from '../../actions';
import { dialogCss } from './dialogCss';
import { DialogCreateIdea } from './DialogCreateIdea';
import { DialogCustomTitle } from './DialogCustomTitle';
import { useCreateIdeaController } from './hooks/useCreateIdeaController';
import { CreateIdeaActions } from './CreateIdeaActions';

const ExpansionPanel = withStyles(theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    '&:before': { display: 'none' },
    '&$expanded': { margin: 'auto', },
  },
  expanded: {},
})) (MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    padding: 0,
    height: 64,
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {}
})) (MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    fontWeight: 'none',
    padding: theme.spacing(1, 2, 1, 3),
  }
})) (MuiExpansionPanelDetails);

const useStyles = makeStyles((theme) => ({
  dividerBottom: {
    borderBottom: 'none',
  },
  name: {
    fontSize: 16,
    color: theme.palette.neutrals.low.main,
    textTransform: 'math-auto',
  },
  description: {
    fontSize: 13,
  },
  error: {
    color: theme.palette.auxiliary.red.main
  }
}));

export function DialogClassification() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dialogClasses = dialogCss();
  const { handleSubmit, register } = useForm();
  const { dialogClassification } = useSelector((state) => state.ideaCreateDialogs);
  const { personalizedClassifications } = useSelector((state) => state.ideaCreate);
  const { personalizedClassification } = useSelector((state) => state.personalizedClassification);
  const [error, setError] = useState(false);
  const { previous, close } = useCreateIdeaController();

  useEffect(() => {
    if (dialogClassification) {
      dispatch(personalizedClassificationActions.getPersonalizedClassification());
    }
  }, [dialogClassification]);

  useEffect(() => {
    if (personalizedClassifications.length > 0) {
      setError(false);
    }
  }, [personalizedClassifications]);

  function onSubmit() {
    if (personalizedClassifications.length > 0) {
      dispatch(ideaCreateDialogsActions.openDialogSendIdea('DIALOG_CLASSIFICATION'));
    } else {
      setError(true);
    }
  }

  function selectedClassification(itemPersonalizedClassification) {
    const itemChecked = isItemCheckedClassification(itemPersonalizedClassification)

    if(!itemChecked) {
      dispatch(ideaCreateActions.setClassification(itemPersonalizedClassification));
    } else {
      dispatch(ideaCreateActions.dropClassification(itemPersonalizedClassification._id));
    }
  }

  function isItemCheckedClassification(itemPersonalizedClassification) {
    const isChecked = personalizedClassifications.some((item) => item._id === itemPersonalizedClassification._id);

    return !!isChecked;
  }

  return(
    <DialogCreateIdea open={dialogClassification}>
      <DialogCustomTitle goBack={previous} onClose={close}>
        {t('como_sua_ideia_pode_ser_classificada')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormLabel component="legend" className={dialogClasses.subtitle}>
            {t('selecione_uma_ou_mais_categorias_que_se_adequam_a_proposta_da_ideia')}
          </FormLabel>
          {error && <Typography className={classes.error}>{t('escolha_pelo_menos_uma_classificacao')}</Typography>}
          {personalizedClassification && personalizedClassification?.map((item, index) => (
            <ExpansionPanel key={index}>
              <ExpansionPanelSummary
                classes={{root:"root", expanded: 'expanded'}}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <FormControlLabel
                  key={item._id}
                  value={item._id}
                  label={item.name}
                  inputRef={register}
                  control={<Checkbox color="primary" />}
                  className={classes.name}
                  onChange={() => selectedClassification(item)}
                  checked={isItemCheckedClassification(item)}
                />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className={classes.description}>
                  {item.description}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}

        </FormControl>
      </DialogContent>

      <CreateIdeaActions name="DIALOG_CLASSIFICATION" onSubmit={handleSubmit(onSubmit)} />
    </DialogCreateIdea>
  );
}