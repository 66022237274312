import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from './styles';

export function ArticleNextArrow(props) {
  const { onClick } = props;

  return (
    <Button
      onClick={onClick}
      style={{ position: 'absolute', right: 15, top: '40%' }}
    >
      <ChevronRightIcon fontSize="large" />
    </Button>
  );
}