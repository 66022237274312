import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography, CircularProgress } from '@material-ui/core';
import {
  DeadlineCollapse,
  DetailsCollapse,
  FinancialFeedbackCollapse,
  CostStructureCollapse,
  DialogJustification,
} from '../../components';
import { useParams } from 'react-router-dom';
import { IdeaCss } from './IdeaCss';

function IdeaResults() {
  const classes = IdeaCss();
  const { id } = useParams();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const { loading } = useSelector(state => state.execution);

  function onCloseDialogJustification() {
    setOpenDialog(false);
  }

  return (
    loading ? (
      <Box display='flex' justifyContent='center' alignItems='center' height='80vh' width='100%'>
        <CircularProgress />
      </Box>
    ) : (
      <>
      <DialogJustification
        open={openDialog}
        onClose={onCloseDialogJustification}
        id={id}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" className={classes.titleUpperCase}>
            {t('resultados')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DeadlineCollapse isResults />
        </Grid>
        <Grid item xs={12}> 
          <CostStructureCollapse isResults />
        </Grid>
        <Grid item xs={12}>
          <FinancialFeedbackCollapse isResults />
        </Grid>
        <Grid item xs={12}>
          <DetailsCollapse isResults />
        </Grid>
      </Grid>
    </>
    )
  );
}

export { IdeaResults };
