import React, { useEffect } from 'react';
import { makeStyles, Box, Grid, Container, Typography, CircularProgress  } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { contentsActions } from '../../actions';
import { BackNavAdmin } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  title: {
    color: theme.palette.neutrals.low.main,
    fontSize: 14,
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: 12,
    color: theme.palette.neutrals.low.light,
    fontWeight: 500,
  },
  link: {
    color: theme.palette.neutrals.low.dark,
    fontSize: 12,
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

function ContentsDetailsPage({ title }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contentsByStatus, contentLoading } = useSelector(state => state.contents);
  const hasContentsByStatus = contentsByStatus.length > 0;

  useEffect(() => {
    dispatch(contentsActions.getContentsByStatus(1, { created: -1 }, 100, true));
  }, [dispatch]);

  return (
    <>
      <BackNavAdmin title="conteúdos" pathname="/" />

      <Container maxWidth="lg" className={classes.root}>
        {contentLoading && (
          <Box height="50vh" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress color="primary" />
          </Box>
        )}

        {(!contentLoading && hasContentsByStatus) && (
          <Grid container spacing={3}>
            {contentsByStatus.map((content, index) => (
              <Grid item xs={12} key={index}>
                <Typography variant="subtitle2" className={classes.title}>
                  {content.link ? (
                    <a
                      className={classes.title}
                      href={content.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ wordWrap: 'break-word' }}
                    >
                      {content.title}
                    </a>
                  ) : content.title}
                </Typography>
                <Typography className={classes.subTitle}>{content.description}</Typography>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </>
  );
}

export { ContentsDetailsPage };