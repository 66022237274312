import {
  styled,
  Box,
  IconButton
} from '@material-ui/core';

export const Button = styled(IconButton)(({ theme }) => ({
  width: 48,
  height: 48,
  backgroundColor: theme.palette.neutrals.high.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    backgroundColor: theme.palette.neutrals.high.main,
    boxShadow: '-20px -20px 60px #bebebe, 20px 20px 60px #ffffff'
  }
}));
