import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  TableCell,
  Typography,
} from "@material-ui/core";
import TableChartIcon from '@material-ui/icons/TableChart';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import config from '../../config';
import { history } from '../../helpers';
import { useWindowSize } from '../../hooks';
import { reportActions } from "./../../actions";
import { tableCss } from './../../components/Tables/TableCss';
import { GraphicPanel, PieChartImagine } from "./../../components";
import { TablePanel, ReportCards, ReportLayout } from "./../../components";
import { CustomTooltip } from '../../components/Shared';

const useStyles = makeStyles((theme) => ({
  tabs: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: 8, 
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    background: 'white',
  },
  itemTab: {
    fontsize: 14,
    fontWeight: 600,
    textTransform: "lowercase",
    marginLeft: theme.spacing(0.8)
  },
}))


export const ReportStatusIdeaPendingManagers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableClasses = tableCss();
  const [arrItensCard, setArrItensCArd] = useState({
    cardContent: [],
    dialogContent: []
  })
  const [width] = useWindowSize();
  const { 
    tab,
    report, 
    chart,
  } = useSelector(state => state.report); 
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();

  function handleView(id) {
    window.open(`/idea/${id}/general`, '_blank');
  }

  const tableInfo = {
    columns: [
      { id: 'approverName', label: t("aprovador") },
      { id: 'approverEmail', label: t("email") },
      { id: "code", label: t('codigo') },
      { id: 'ideaName', label: t('nome_da_ideia') },
      { id: 'ideaDate', label: t('data') },
      { id: 'local', label: t('local') },
      { id: 'occupationArea', label: t('area') },
      { id: 'businessUnit', label: t('unidade') },
      { id: 'authorName', label: t("autor") },
      { id: 'authorEmail', label: t("email") },
    ],
    data: report.map(item => {
      return (
        <Fragment>
          <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>{item.approverName && item.approverName.length > 0 ? item.approverName : ""}</TableCell>
          <TableCell classes={{ root: tableClasses.tableCell }}>{item.approverEmail && item.approverEmail.length > 0 ? item.approverEmail : ""}</TableCell>
          <TableCell classes={{ root: tableClasses.tableCell }}>{item.ideaCode || ""}</TableCell>
          <TableCell classes={{ root: tableClasses.tableCell }}>
            <CustomTooltip title={item.ideaName} aria-label={item.ideaName} placement="bottom-start">
              <Typography variant="subtitle2" className={tableClasses.link} onClick={() => handleView(item.ideaId)}>{item.ideaName}</Typography>
            </CustomTooltip>
          </TableCell>
          <TableCell classes={{ root: tableClasses.tableCell }}>{item.ideaDate}</TableCell>
          <TableCell classes={{ root: tableClasses.tableCell }}>{item.local}</TableCell>
          <TableCell classes={{ root: tableClasses.tableCell }}>{item.occupationArea}</TableCell>
          <TableCell classes={{ root: tableClasses.tableCell }}>{item.businessUnit}</TableCell>
          <TableCell classes={{ root: tableClasses.tableCell }}>{item.authorName}</TableCell>
          <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>{item.authorEmail}</TableCell>
        </Fragment>
      )
    }),
  }

  useEffect(() => {
    setArrItensCArd({
      cardContent: report.map(item => (
        [
          {
            name: "nome da ideia",
            value: item.name,
          },
          {
            name: "status",
            value: item.statusIdea?.name
          },
          {
            name: "local",
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.local?.name : ""
          }
        ]
      )),
      dialogContent: report.map(item => (
        [
          {
            name: "code",
            value: item.code,
          },
          {
            name: t("autor"),
            value: item.userName,
          },
          {
            name: t("aprovador"),
            value: item.approver && item.approver.length > 0 ? item.approver[0].name : "",
          },
          {
            name: t("email"),
            value: item.approver && item.approver.length > 0 ? item.approver[0].email : "",
          },
          {
            name: "nome da idéia",
            value: item.name,
          },
          {
            name: "status",
            value: item.statusIdea?.name
          },
          {
            name: "local",
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.local?.name : ""
          },
          {
            name: "area",
            value: item.businessUnitLocal?.length > 0 ? item.businessUnitLocal[0]?.occupationArea?.name : ""
          },
          {
            name: "data",
            value: moment(item.createAt).format('DD/MM/YYYY')
          },
          {
            name: "tipo / âmbito",
            value: item.methodology?.name
          },
          {
            name: "data de conclusão",
            value: moment(item?.conclusionDate).format('DD/MM/YYYY')
          },
          {
            name: t("desafio"),
            value: item.challenge?.name,
          },
        ]
      ))
    })
  }, [report, t])

  useEffect(() => {
    return () => {
      dispatch(reportActions.clearAll())
    }
  }, [dispatch])

  function handleChangeTab(index) {
    dispatch(reportActions.changeTab(index === 0 ? "table" : "graph"));
    setTabIndex(index)
  }
  


  return (
    <ReportLayout title={t('status_das_ideias_pendetes_managers')} tableInfo={tableInfo.columns}>
      <Tabs className={classes.tabs} value={tabIndex} onChange={(e, value) => handleChangeTab(value)} aria-label="Tabs de grafico e tabela" indicatorColor="primary" textColor="primary" variant="fullWidth">
        <Tab
          aria-controls="Panel tabela"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <TableChartIcon />
              <Typography className={classes.itemTab}>
                {t('tabela')}
              </Typography>
            </Box>
          )}
        />
        <Tab
          disabled
          aria-controls="Panel grafico"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <DonutLargeIcon />
              <Typography className={classes.itemTab}>
                {t('grafico')}
              </Typography>
            </Box>
          )}
        />
      </Tabs>

      {tab === "table" && (
        <Fragment>
          {width > config.RESPONSIVE_MOBILE ?
            <TablePanel tableInfo={tableInfo} /> :
            <ReportCards arrItems={arrItensCard} />
          }
        </Fragment>
      )}


    </ReportLayout>
  )
}
