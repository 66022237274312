import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, makeStyles, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import {
  ideaActions,
  portfolioExecutionFlow,
  personalizedClassificationActions,
  ideaCreateActions,
  executionActions,
  ideaConclusionActions,
  fluxOfIdeasActions
} from '../../actions';
import { BackNavAdmin, IdeaDetailsLayout, FluxOfIdeasLayout } from '../../components';
import { useIdeaDetailsController } from './hooks/useIdeaDetailsController';
import { useCanEditableIdea } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 14,
      paddingRight: 14,
    },
  },
}));

export const IdeaDetailsPage = () => {
  const { page } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { typeProfile, _id: userId } = useSelector((state) => state.user);
  const { videoRender, imageRender, fileRender } = useSelector(state => state.avaliationDialogs);
  const {
    id,
    stepflux,
    hasIdeaFlux,
    hasLoadingFlux,
    ideaDetailsFlux,
    handleEditIdea,
    handleSendIdea,
  } = useIdeaDetailsController();
  
  const decisions = hasIdeaFlux?.currentStepFlux?.ideaStepFlux?.decisions;
  const visualizationConfig =  hasIdeaFlux?.currentStepFlux?.ideaStepFlux?.visualizationConfigs;
  const isPendingReviewStatus = hasIdeaFlux?.statusIdea?.code === 'PENDING_REVIEW';

  useEffect(() => {
    if (visualizationConfig?.showInExecutionTab ||
        visualizationConfig?.showInValidationTab ||
        hasIdeaFlux?.currentStepFlux?.ideaStepFlux?.name === 'Ideia Concluída' ||
        hasIdeaFlux?.statusIdea?.code === 'CONCLUDED') {
      dispatch(executionActions.getIdea(id));
    }
  }, [dispatch, id, hasIdeaFlux]);

  useEffect(() => {
    if (visualizationConfig?.showInExecutionTab && hasIdeaFlux?.statusIdea?.code === 'EXECUTION_QUEUE') {
      dispatch(executionActions.loadArrayCosts());
    }
  }, [dispatch, hasIdeaFlux]);
  
  useEffect(() => {
    dispatch(portfolioExecutionFlow.getProjects(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(ideaActions.clearApprovedDetails());
  }, []);

  useEffect(() => {
    dispatch(personalizedClassificationActions.getPersonalizedClassification());
  }, [dispatch]);

  useEffect(() => {
    if (hasIdeaFlux?.statusIdea?.code === 'DRAFT') {
      dispatch(ideaCreateActions.loadIdeaDetailsDraft(hasIdeaFlux));

      return () => { dispatch(ideaCreateActions.clearAll()); }
    }
  }, [hasIdeaFlux]);

  useEffect(() => {
    return () => {
      dispatch(ideaActions.clearIdeaDetails());
      dispatch(executionActions.clear());
      dispatch(ideaConclusionActions.clear());
    }
  }, [dispatch]);

  function handleEvaluateIdea() {
    let data = {
      decision: decisions[0]
    }

    dispatch(fluxOfIdeasActions.updateIdeaStageInFlux(
      id,
      data,
      t('sua_avaliacao_foi_feita_com_sucesso'),
      hasIdeaFlux?.currentStepFlux?.ideaStepFlux?.attachmentsRequired,
      [...imageRender, ...videoRender, ...fileRender],
    ));
  }

const canEditIdea = () => {
  const isContributor = hasIdeaFlux?.contributors?.some(item => item?._id === userId);
  const isOwner = hasIdeaFlux?.user?._id === userId;
  const isAdmin = typeProfile.type === 'admin';
  const stepId = ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?._id;

  const stepHasEditableIdea = useCanEditableIdea(stepId);
  const rolesVerify = (isContributor || isOwner || isAdmin);

  if (stepflux) {
    return rolesVerify && stepHasEditableIdea;
  }

  return (
    rolesVerify &&
    hasIdeaFlux?.statusIdea?.code !== 'CANCELED' &&
    hasIdeaFlux?.statusIdea?.code !== 'CONCLUDED' &&
    hasIdeaFlux?.statusIdea?.code !== 'UNQUALIFIED' 
  );
};

const shouldRenderEditButton = canEditIdea();

  return (
    <>
      <BackNavAdmin title={hasLoadingFlux ? 'carregando...' : hasIdeaFlux?.name}>
        {isPendingReviewStatus && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<SendIcon />}
            onClick={handleEvaluateIdea}
            disabled={hasLoadingFlux}
            style={{ marginRight: 14 }}
          >
            {t('enviar_para_qualificacao')}
          </Button>
        )}

        {(typeProfile.type === 'admin' ||
          (hasIdeaFlux?.user?._id === userId && 
          hasIdeaFlux?.statusIdea?.code === 'DRAFT') || 
          shouldRenderEditButton
        ) && (
          <Button
            color="primary"
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={handleEditIdea}
            disabled={hasLoadingFlux}
          >
            {t('editar_ideia')}
          </Button>
        )}
        {hasIdeaFlux?.statusIdea?.code === 'DRAFT' && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleSendIdea}
            disabled={hasLoadingFlux}
            style={{ marginLeft: 10 }}
          >
            {t('enviar')}
          </Button>
        )}
      </BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        {stepflux ? <FluxOfIdeasLayout /> : <IdeaDetailsLayout />}
      </Container>
    </>
  );
}
